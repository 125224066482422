/********************************************/
// Capa de Servicios para el modulo de Blogs.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + store.getters.getToken,
    },
  };

  constructor() {
    // Inicializar campos y propiedades.
    this.#baseUrl = store.getters.getBaseURL;
  }

  async getAllRecords() {
    try {
      let response = await axios.get(this.#baseUrl + "/blogs");
      // console.log('response: ', response);
      if (response.data.success) {
        // console.log("response.data:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      // console.error('Error fatal:', error);
      return this.handleError(error);
    }
  }

  async getViewList() {
    try {
      let response = await axios.get(this.#baseUrl + "/view-list/blogs");
      // console.log('response: ', response);
      if (response.data.success) {
        // console.log("records:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      // console.error('Error fatal:', error);
      return this.handleError(error);
    }
  }

  async getViewListPosts() {
    try {
      let response = await axios.get(this.#baseUrl + "/view-list-posts/blogs");
      // console.log('response: ', response);
      if (response.data.success) {
        // console.log("records:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      // console.error('Error fatal:', error);
      return this.handleError(error);
    }
  }

  async getViewListRecents() {
    try {
      let response = await axios.get(
        this.#baseUrl + "/view-list-recents/blogs"
      );
      // console.log('response: ', response);
      if (response.data.success) {
        // console.log("records:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      // console.error('Error fatal:', error);
      return this.handleError(error);
    }
  }

  async getRecord(id) {
    try {
      let response = await axios.get(`${this.#baseUrl}/blogs/${id}`);
      if (response.data.success) {
        // console.log("records:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      // console.log(error);
      return this.handleError(error);
    }
  }

  async addRecord(formData, picture_id, imageFile) {
    // console.log('Adding Record.', formData);
    try {
      let categoriesList = formData.categories;
      let recordData = new FormData();
      recordData.append("id", formData.id);
      recordData.append("title", formData.title);
      recordData.append("title_english", formData.title_english);
      recordData.append("content", formData.content);
      recordData.append("content_english", formData.content_english);
      recordData.append("caption_image", formData.caption_image);
      recordData.append("author", formData.author);
      // console.log('Record-Data: ', recordData);
      let response = await axios.post(this.#baseUrl + "/blogs", recordData);
      if (response.data.success) {
        // console.log('Respuesta: ', response.data);
        let blog_id = response.data.id;
        this.uploadImage(blog_id, picture_id, imageFile);

        // Sincroniza las categorias con el Post
        let categoriesIds = [];
        categoriesList.forEach((item) => {
          categoriesIds.push(item.id);
        });
        this.registerCategories(blog_id, categoriesIds);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  // Pushes posts to the server when called.
  async updateRecord(formData, picture_id, imageFile) {
    try {
      let result = null;
      let categoriesList = formData.categories;
      let recordData = new FormData();
      recordData.append("id", formData.id);
      recordData.append("title", formData.title);
      recordData.append("title_english", formData.title_english);
      recordData.append("content", formData.content);
      recordData.append("content_english", formData.content_english);
      recordData.append("caption_image", formData.caption_image);
      recordData.append("author", formData.author);
      recordData.append("_method", "put");
      // console.log('Record-Data: ', recordData);
      let response = await axios.post(
        `${this.#baseUrl}/blogs/${formData.id}`,
        recordData
      );
      if (response.data.success) {
        result = response.data;
        let blog_id = result.id;
        this.uploadImage(blog_id, picture_id, imageFile);

        // Sincroniza las categorias con el post
        let categoriesIds = [];
        categoriesList.forEach((item) => {
          categoriesIds.push(item.id);
        });
        if (categoriesIds.length > 0) {
          this.registerCategories(blog_id, categoriesIds);
        }
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async deleteRecord(id) {
    try {
      // console.log('Deleting Record: %s ', id);
      let response = await axios.delete(`${this.#baseUrl}/blogs/${id}`);
      if (response.data.success) {
        // console.log('Respuesta: ', response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async registerCategories(blog_id, categoriesIds) {
    try {
      let formData = new FormData();
      formData.append("blog_id", blog_id);
      formData.append("categories_ids", categoriesIds);
      let response = await axios.post(
        this.#baseUrl + "/register-categories/blogs",
        formData
      );
      if (response.data.success) {
        // console.log('Respuesta: ', response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async uploadImage(blog_id, picture_id, imageFile) {
    if (imageFile) {
      try {
        let formData = new FormData();
        formData.append("blog_id", blog_id);
        formData.append("picture_id", picture_id);
        formData.append("file", imageFile);
        let response = await axios.post(
          this.#baseUrl + "/upload-image/blogs",
          formData
        );
        if (response.data.success) {
          // console.log('Respuesta: ', response.data);
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async deleteImage(blog_id, picture_id) {
    if (picture_id) {
      try {
        let formData = new FormData();
        formData.append("blog_id", blog_id);
        formData.append("picture_id", picture_id);
        let response = await axios.post(
          this.#baseUrl + "/delete-image/blogs",
          formData
        );
        if (response.data.success) {
          // console.log('Respuesta: ', response.data);
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  handleError(error) {
    let valueError;
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      // console.log('Error response: %s', error.response.data.message);
      valueError = error.response.data.messaje;
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      valueError = error.request.data;
      // console.log(error.request);
      // console.error('Error request: ', error.request.data);
    } else {
      // Something happened in setting up the request and triggered an Error
      valueError = error.message;
      // console.log('Error', error.message);
    }
    // console.log(error.config);
    return valueError;
  }
})();
